@import './../../index.css';

.capital-view-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.chart-container {
    width: 100%;
    background: linear-gradient(to top right, var(--gradient-end-color), var(--gradient-start-color) 50%);
    border-width: 2px;
    border-color: var(--border-color);
    border-right-style: solid;
    border-top-style: solid;
    border-bottom-style: solid;
    border-image: linear-gradient(to right, black, var(--border-color)) 1; 
    padding: 15px;
    margin-bottom: 40px;
    flex: 1;
    height: 100%; /* Set chart container height */
    display: flex;
    flex-direction: column;
}

.chartheading {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
    text-align: center;
    margin: 0 0 20px 0;
}

.chart-note {
    text-align: center;
    color: #888;
    font-style: italic;
    margin: 10px 0;
}

.chart-wrapper {
    width: 100%;
    overflow: hidden;
}

.toggle-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

/* Add to your capitalView.css */
.charts-row {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.chart-col {
    flex: 1;
    min-width: 0; /* Prevents flex items from overflowing */
}

@media (max-width: 768px) {
    .charts-row {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .capital-view-container {
        padding: 10px;
        gap: 20px;
    }

    .chart-container {
        padding: 10px;
        margin-bottom: 20px;
    }

    .chartheading {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .toggle-container {
        margin: 10px 0;
    }

    .chart-wrapper {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .chart-wrapper::-webkit-scrollbar {
        display: none;
    }

    .chart-note {
        font-size: 12px;
        margin: 8px 0;
    }
}

@media (min-width: 769px) {
    .chart-wrapper {
        overflow-y: hidden;
    }

    .capital-view-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .chart-wrapper {
        flex-grow: 1;
    }
}

.capital-view-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.chart-item {
    width: 100%;
}

@media (min-width: 769px) {
    .charts-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .chart-item {
        width: calc(50% - 10px);
    }
}

@media (max-width: 768px) {
    .chart-container {
        padding: 15px;
        margin-bottom: 20px;
        border-width: 2px;
        border-color: var(--border-color);
        border-right-style: solid;
        border-top-style: solid;
        border-bottom-style: solid;
        border-image: linear-gradient(to right, black, var(--border-color)) 1;
    }
    
    .capital-view-container {
        gap: 60px;
    }

    .chart-item {
        margin-bottom: 0;
    }
}

