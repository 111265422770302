.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #010201;
  border-bottom: 2px solid #444449;
  height: 85px;
}

.navbar-brand .logo {
  height: 50px;
  width: 100px;
}

.navbar-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: bold;
}

.navbar-links a:hover {
  color: var(--primary-color);
}

.last-update-card {
  background-color: rgba(1, 255, 133, 0.1);
  border: 1px solid #01FF85;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.last-update-label {
  font-size: 0.7rem;
  color: #01FF85;
  margin-bottom: 2px;
}

.last-update-time {
  font-size: 0.8rem;
  color: #ffffff;
  font-weight: bold;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: auto;
    padding: 10px;
    position: relative;
  }
  
  .navbar-brand {
    align-self: flex-start;
  }
  
  .hamburger-menu {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .navbar-center {
    position: static;
    transform: none;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .navbar-center.active {
    max-height: 300px;
  }
  
  .navbar-links {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  
  .navbar-links li {
    margin: 10px 0;
  }
  
  .last-update-card {
    margin-top: 20px;
    align-self: center;
  }
}

@media (max-width: 768px) {
  .last-update-card {
    display: none;
  }

  .navbar {
    justify-content: space-between;
  }

  .navbar-brand {
    flex: 1;
  }

  .hamburger-menu {
    flex: 0 0 auto;
  }
}