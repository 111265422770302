
.main2{
  margin: 30px 100px 100px 100px;
}

/* App.css */

.footer {
  background-color: var(--gradient-start-color);
  padding: 20px;
  text-align: center;
  color: var(--text-color);
  margin-top: 50px;
}

.footer-content p {
  margin-bottom: 10px;
  font-size: 16px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icons a {
  color: var(--text-color);
  font-size: 24px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: var(--link-color);
}
