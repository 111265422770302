@import './../../index.css';

/* General layout */
.staking-view-container,
.staking_time_main_grid,
.mor-staking-overview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

/* Grid layouts */
.staking_reward_main_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
}

.staking_time_main_grid_2 {
    display: grid;
    grid-template-columns: 25% 40% 35%;
    grid-gap: 1em;
}

.mor_staking_overview_main_grid_3 {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 1em;
}

.mor_staking_overview_sub_grid_3 {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 1em;
}

/* Flex layouts */
.staking_reward_main_flex,
.staking_metrics_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/* Toggle and info styles */
.toggle_and_info,
.toggle-and-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.info_icon,
.info-icon {
    position: relative;
}

.tooltip {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    z-index: 1000;
    width: 200px;
    text-align: left;
    font-size: 12px;
}

/* Metrics card styles */
.staking_metrics_card,
.metrics-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
}

.staking_metrics_card > div {
    flex: 1 1 calc(33.333% - 10px);
    min-width: 200px;
}

/* Chart styles */
.charts-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.chart-item {
    flex: 1;
    min-width: 0;
}

.staking_chart_background,
.staking-chart-background {
    background: linear-gradient(to top right, var(--gradient-end-color), var(--gradient-start-color) 50%);
    border-width: 2px;
    padding: 15px;
    border-color: var(--border-color);
    border-right-style: solid;
    border-top-style: solid;
    border-bottom-style: solid;
    border-image: linear-gradient(to right, black, var(--border-color)) 1; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.chartheading {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
    text-align: center;
    margin: 0 0 15px 0;
}

/* Cumulative stakers chart styles */
.cumulative-stakers-chart-container {
    width: 100%;
}

.cumulative-stakers-chart-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to top right, var(--gradient-end-color), var(--gradient-start-color) 50%);
    border-width: 2px;
    border-color: var(--border-color);
    border-right-style: solid;
    border-top-style: solid;
    border-bottom-style: solid;
    border-image: linear-gradient(to right, black, var(--border-color)) 1; 
    padding: 15px;
}

.cumulative-stakers-chart-container .chart-wrapper {
    width: 100%;
    overflow: hidden;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .staking_reward_main_grid,
    .staking_time_main_grid_2,
    .mor_staking_overview_main_grid_3 {
        grid-template-columns: 1fr;
    }
    
    .staking_metrics_card > div {
        flex: 1 1 100%;
    }
    
    .charts-container {
        flex-direction: column;
    }

    .chart-item {
        width: 100%;
    }

    .chartheading {
        font-size: 20px;
    }

    .cumulative-stakers-chart-container.mobile,
    .cumulative-stakers-chart-container.mobile .cumulative-stakers-chart-content {
        /* border: none;
        border-image: none; */
        padding: 10px;
    }

    .cumulative-stakers-chart-container.mobile .chart-wrapper {
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .cumulative-stakers-chart-container.mobile .chart-wrapper::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .cumulative-stakers-chart-container.mobile .chartheading {
        font-size: 18px;
        margin-bottom: 10px;
    }

    /* Make the metrics grid filter wider */
    .toggle_and_info {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 15px;
    }

    .toggle-group {
        width: 100%;
        margin-bottom: 10px;
    }

    .toggle-button {
        flex: 1;
        padding: 10px;
    }

    /* Adjust the metrics card layout */
    .staking_metrics_card {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .staking_metrics_card > div {
        width: 100%;
    }

    /* Add space between APY and Daily MOR Rewards cards */
    .staking_reward_main_grid {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .staking_reward_main_flex {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .info_icon,
    .info-icon {
      display: none;
    }
  
    .toggle_and_info,
    .toggle-and-info {
      justify-content: center;
    }
  }