.code-view-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.charts-row {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.chart-col {
    flex: 1;
    min-width: 0;
}

@media (max-width: 768px) {
    .code-view-container {
        padding: 10px;
        gap: 20px;
    }

    .charts-row {
        flex-direction: column;
    }

    .chart-col {
        width: 100%;
        margin-bottom: 20px;
    }
}